import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import uk from '@angular/common/locales/uk';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N, uk_UA } from 'ng-zorro-antd/i18n';
import { PixelModule } from 'ngx-multi-pixel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


registerLocaleData(uk);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    PixelModule.forRoot({ enabled: true, pixelId: ["535999992003980"] }),
  ],
  providers: [{ provide: NZ_I18N, useValue: uk_UA }],
  bootstrap: [AppComponent]
})
export class AppModule { }
