import { Component } from '@angular/core';
import { PixelService } from 'ngx-multi-pixel';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent {
  constructor(private pixel: PixelService) {
  }
}
